@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

* { font-family: 'Roboto', sans-serif; }

label.required::after,
legend.required::after {
    content: ' *';
    color: red;
}

.navbar-brand-logo { max-height: 25px; }

body {
    /*background: linear-gradient(#EEE,#FFF);*/
    background: url("/images/cactes_light.png") #EEE;
    /* color: #DDD; */
    /*margin-bottom: 60px;*/
}

.fix-p p:last-child { margin-bottom: 0; }
.fix-p img { max-width: 100%; }

html {
    position: relative;
    min-height: calc(100% - 2px);
}

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
}

.navbar-black {
    background-color: #111!important;
}

.banner-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    background:#FFF;
}
.banner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding-top: 56.25%;
    background:#FFF;
}

.banner-container .banner-thumb { display: block; }
.banner-container .banner-full { display: none;}
.banner-container:hover .banner-thumb { display: none; }
.banner-container:hover .banner-full { display: block;}

.cursor-pointer { cursor: pointer }

.profile-header {
    background: url(/images/logo_dark.png) 105% center no-repeat #FFF;
    background-size: 20%;
}

.start-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    color: #CCC;
}

.start-content {
    width: 1400px;
    max-width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.start-promo {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.start-promo video {
    position: fixed;
    top: 50%;
    left: 50%;
    height: 150%;
    transform: translate(-50%, -50%);
}

.grid3 {
    display: grid;
    gap: 0.5rem;
}

.grid4 {
    display: grid;
    gap: 0.5rem;
}

.featured {
    background: #111; position: relative; overflow: hidden;
    color: #EEE;
    z-index: 0;
}

.featured-grid {
    display: grid;
    gap: 1em;
    margin: 1rem;
}

.featured-background {
    position: absolute;
    top: 0;
    left: 50%;
    width: 200%;
    opacity: 0.1;
    z-index: -1;
    transform: translateX(-50%);
}

.project-grid {
    display: grid;
    gap: 1rem;
}

.project-content {
}

.project-panel {
}

.visuals-grid {
    display: grid;
    gap: 0.5rem;
    /*grid-template-columns: repeat(auto-fit, minmax(200px, 3fr));*/
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.articles-grid {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.video-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000CC;
    z-index: 10000;
}

.video-source {
    position: fixed;
    left: 50%;
    top: 50%;
    max-width: calc(100% - 2rem);
    max-height: calc(100% - 2rem);
    transform: translate(-50%, -50%);
}

.video-controls {
    position: fixed;
    right:0;
    top:0;
    width: 6rem;
    height: 6rem;
    background: #111;
    color: #FFF;
    text-align: center;
    line-height: 6rem;
    font-size: 3rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

@media (min-width: 60em) {
    .grid3 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .grid4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .featured-grid {
        gap: 1.5rem;
        margin: 3rem;
    }

    .featured-background {
        position: absolute;
        left:0;
        top:50%;
        width: 100%;
        transform: translateY(-50%);
        filter: blur(5px);
        opacity: 0.2;
    }

    .video-controls {
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        font-size: 2rem;
        transition: .5s;
    }

    .video-controls:hover {
        transform: scale(0.9, 0.9);
        transition: .5s;
    }
}

@media (min-width: 70rem) {
    .project-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .project-content {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
    }

    .project-panel {
        grid-column-start: 3;
        grid-row-start: 1;
    }
}
